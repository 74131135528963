<template>
  <v-container
    fluid
    tag="section"
  >
    <material-wizard
      title="Nova Carga de BDGD"
      subtitle="Selecione o mês e entidades que serão carregadas no servidor"
      v-model="tab"
      :available-steps="tabsLiberadas"
      :tabs="tabs"
      class="mx-auto"
      @click:next="next()"
      @click:prev="back()"
      :loading="loading"
    >
      <carregar-bdgd-wizard-tabs
        ref="carregarBdgdParametros"
        @step-observer="updateTabStatus($event)"
      />
    </material-wizard>
  </v-container>
</template>

<script>
import CargasBdgdsService from '@/services/CargasBdgdsService';
import wizardMixins from '@/mixins/wizardMixins';
import routes from '@/store/modules/routes';

export default {
  mixins: [wizardMixins],
  components: {
    CarregarBdgdWizardTabs: () =>
      import(
        '@/components/perdas-tecnicas/carregar-bdgd/CarregarBdgdWizardTabs'
      ),
    MaterialWizard: () => import('@/components/base/MaterialWizard')
  },
  data: () => ({
    tabs: ['Mês dos Dados', 'Entidades', 'Confirmação'],
    loading: false
  }),
  methods: {
    save() {
      const data = this.$refs.carregarBdgdParametros.exportData();
      this.loading = true;
      CargasBdgdsService.save(data)
        .then(() => {
          this.$toast.success('Cargas de BDGD salvas com sucesso.', '', {
            position: 'topRight'
          });
          this.$router.replace({
            name: routes.state.appRoutes['CARREGAR_BDGD_HISTORICO']
          });
        })
        .catch(() => {
          this.$toast.error('Erro ao salvar as novas cargas de BDGD.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>
